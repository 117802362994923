/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
declare var angular: angular.IAngularStatic;

import * as Flow from '@flowjs/flow.js';

import { StatusConstant } from '../../constants/status.constant';
import { AuthConstant } from '../../constants/auth.constant';

export class SubmitController {
    // Private
    private sessionData: any = {};
    private brandData: any = null;
    private statusConstants: any = new StatusConstant();
    private authConstants: any = new AuthConstant();
    private retrieveStatus: any; // Method reference

    // Bindable
    public currentActiveSubmits: any|null = null;
    public guide: any|null = null;
    public submission: any = {};
    public durations: any[] = [];
    public destinations: any|null = null;
    public submitGridApi: any|null = null;
    public statuses: any = this.statusConstants.spots;
    public activeSubmits: any;
    public activeUploads: any;
    public isAdmin: Boolean = false;
    public submitting: Number = 0; // TODO: Evaluate if this value is ever used
    public isciValidator: RegExp = /^[a-zA-Z0-9]+$/;
    public maxIsciLength: Number = 100;
    public submitGridOptions: any;
    public requireIsci: Boolean = false;
    public destinationType: String;
    public refreshingSubmits: Boolean = false;
    public submitMeta: any = {};

    // A/A/B
    public searchClients: String = '';
    public searchAgencies: String = '';
    public searchBrands: String = '';

    static get $inject() {
        return [
            'loginService',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'BrandsResourceFactory',
            'DestinationResourceFactory',
            'EndPointService',
            'LiteDeliveryResourceFactory',
            '$q',
            '$scope',
            '$mdDialog',
            'uiGridConstants',
            'NotificationService',
            'StatusService',
            'submitManager',
            '$stateParams',
            '$timeout',
            '$state',
            '$window',
            'GuideResourceFactory',
            'socket',
        ];
    }

    constructor(
        public loginService: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public BrandsResourceFactory: any,
        public DestinationResourceFactory: any,
        public EndPointService: any,
        public LiteDeliveryResourceFactory: any,
        public $q: any,
        public $scope: any,
        public $mdDialog: any,
        public uiGridConstants: any,
        public NotificationService: any,
        public StatusService: any,
        public submitManager: any,
        public $stateParams: any,
        public $timeout: any,
        public $state: any,
        public $window: any,
        public GuideResourceFactory: any,
        public socket: any,
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        this.sessionData = loginService.getSessionData();
        this.retrieveStatus = StatusService.readViewStatus;

        /* BINDABLE : DATA */
        this.durations = this.sessionData.durations.filter(function (cur:any) {
            return loginService.isAdmin() || cur <= 300;
        });

        // Retrieve all of the active submits and uploads from the manager
        this.$q.all([submitManager.getActiveSubmits, submitManager.getActiveUploads]).then((
            actives:any
        ) => {
            this.activeSubmits = actives[0];
            this.activeUploads = actives[1];
        });

        this.isAdmin = loginService.isAdmin();
        this.destinationType = this.sessionData.destinationType;

        this.submitGridOptions = {
            enableColumnMenus: false,
            appScopeProvider: this,
            enableSorting: false,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            multiSelect: false,
            rowHeight: 60,
            rowTemplate:
                '<div ng-dblclick="grid.appScope.openDetail(row.entity)" mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
            columnDefs: [
                {
                    name: 'title',
                    field: 'title',
                    displayName: 'title',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'title_submit_header',
                },
                {
                    name: 'clientName',
                    field: 'Advertiser.name',
                    displayName: 'client',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'client_submit_header',
                },
                {
                    name: 'isci',
                    field: 'isci',
                    displayName: 'isci/spot #',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    cellTemplate:
                        '<div class="ui-grid-cell-contents">{{(row.entity.expressIsci) ? row.entity.expressIsci : row.entity.isci}}</div>',
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'isci_submit_header',
                },
                {
                    name: 'duration',
                    field: 'duration',
                    displayName: 'duration',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'duration_submit_header',
                },
                {
                    name: 'destination',
                    field: 'OGSpots[0].OrderGroup.OGDestinations[0].Destination.name',
                    displayName: 'destination',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'destination_submit_header',
                },
                {
                    name: 'status',
                    field: 'status',
                    displayName: 'status',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    width: 300,
                    cellTemplate:
                        '<div class="ui-grid-cell-contents"><md-tooltip md-direction="top" style="margin-top: 5px;">{{grid.appScope.readLabel(row.entity)}}</md-tooltip><cts-status-bar data="row.entity.statusBar"></cts-status-bar></div>',
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'status_submit_header',
                },
                {
                    name: 'createdDate',
                    field: 'date_created',
                    cellFilter: "date:'MM/dd/yy h:mm:ss a'",
                    displayName: 'created',
                    sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
                    headerCellTemplate: require('../settings/customHeader-template.html'),
                    headerCellClass: 'createdDate_submit_header',
                },
            ],
        };

        this.submitGridOptions.onRegisterApi = (gridApi:any) => {
            this.submitGridApi = gridApi;
        };

        /* EVENTS */
        this.$scope.$on('changeActiveSubmits', (event:any, submits:any) => {
            // We've got all the submits, now get all the uploads
            this.currentActiveSubmits = submits;
            this._refreshActiveUploads(event, submits);
        });

        socket.on('Spot_Ingest_Progress', (ingestStatus:any) => {
            for (let i = 0; i < this.submitGridOptions.data.length; i++) {
                if (this.submitGridOptions.data[i].id === undefined) {
                    continue;
                } else if (this.submitGridOptions.data[i].id === ingestStatus.spotId) {
                    this.submitGridOptions.data[i].status = ingestStatus.ingestStatus;
                    if (['PROCESSING', 'VERIFYING'].indexOf(ingestStatus.ingestStatus) > -1) {
                        this.submitGridOptions.data[i].progress = ingestStatus.ingestPercent;
                    } else {
                        this.submitGridOptions.data[i].progress = null;
                    }
                }
                this.submitGridOptions.data[i].statusBar = this.retrieveStatus(
                    this.submitGridOptions.data[i]
                );
            }

            if (
                ['SENT', 'UNSENT', 'REJECTED'].indexOf(
                    ingestStatus.ingestStatus.toUpperCase()
                ) > -1
            ) {
                this.refreshSubmits();
            }
        });

        $scope.$on('invalidFileUploaded', () => {
            this.submission.spot = null;
        });
    }

    $onInit() {
        this._reload();
    }

    /* IMPLEMENTATION : BINDABLE */
    disableSubmit() {
        return !(
            this.$scope.liteSubmissionForm &&
            this.$scope.liteSubmissionForm.$valid &&
            this.submission.spot
        );
    }

    refreshSubmits() {
        this.refreshingSubmits = true;
        this.submitManager.getActiveSubmits();
    }

    submit() {
        // Create a new unique id for this upload
        let uploadId = 'UPLOAD' + new Date().getTime();

        this.submitMeta[uploadId] = {
            clientName: this.submission.client
                ? this.submission.client.name
                : this.searchClients,
            agencyName: this.submission.agency
                ? this.submission.agency.name
                : this.searchAgencies,
            brandName: this.submission.brand
                ? this.submission.brand.name
                : this.searchBrands,
            spotTitle: this.submission.title,
            spotIsci: this.submission.isci !== undefined ? this.submission.isci : '',
            destinationId: this.submission.destination,
            expectedDuration: this.submission.duration,
            bypassBaton: this.submission.withoutBaton,
            notes: this.submission.description,
            additionalRecipients:
                this.submission.additionalRecipients !== undefined
                    ? this.submission.additionalRecipients
                    : '',
        };

        let spotDurationConfirm = this.$q.defer();

        // Make sure the user has selected the right duration
        this.$mdDialog
            .show({
                controller: 'SpotDurationDialogController',
                controllerAs: 'vm',
                template: require('./spotDurationDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                locals: {
                    duration: this.submission.duration,
                },
            })
            .then(
                () => {
                    spotDurationConfirm.resolve(true);
                },
                () => {
                    //user cancelled the popup
                    spotDurationConfirm.reject(false);
                }
            );

        // If the user has confirmed the duration, proceed with the upload
        spotDurationConfirm.promise.then(
            () => {
                let fileToUpload = new Flow({
                    target: this.EndPointService.tapUploadEndPoint,
                    headers: () => {
                        let accountHash = JSON.parse(
                            this.$window.localStorage[this.authConstants.session.ACCOUNT_HASH]
                        );
                        let sessionData = JSON.parse(
                            this.$window.sessionStorage[this.authConstants.session.SESSION_DATA]
                        );

                        return {
                            Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                        };
                    },
                    testMethod: false,
                    fileParameterName: 'file',
                    uploadMethod: 'POST',
                    withCredentials: true,
                    allowDuplicateUploads: true,
                    // 5Gb chunks, should force everything to go at once, since it's limited to that max size
                    chunkSize: 5 * 1024 * 1024 * 1024,
                    singleFile: true,
                    query: {
                        ignoreLoadingBar: true,
                        waitForAutoConversions: false,
                    },
                    testChunks: false,
                });

                // Append some tracking info to the file after it's added to the upload queue
                fileToUpload.on('fileAdded', (file:any) => {
                    file.trackingId = uploadId;
                });

                // Add the spot file to the uploader
                fileToUpload.addFile(this.submission.spot);

                this.submitManager.addActiveUpload({
                    id: uploadId, // This is unique only on a per-user-and-per-session basis
                    trackingId: uploadId,
                    title: this.submission.title,
                    Advertiser: {
                        name: this.submission.client
                            ? this.submission.client.name
                            : this.searchClients,
                    },
                    isci: this.submission.isci !== undefined ? this.submission.isci : '',
                    duration: this.submission.duration,
                    destination: this.submission.destination,
                    status: 'UPLOADING',
                    progress: 0,
                });

                // Handle upload events
                fileToUpload.off();
                // Once the upload completes, submit the metadata
                fileToUpload.on('fileError', (file:any, message:any) => {
                    this.submitManager.removeActiveUpload(
                        file.flowObj.opts.query.trackingId
                    );
                    this.NotificationService.showNotificationToast(
                        'Spot upload failed',
                        message
                    );
                    this.refreshSubmits();
                });

                // Track the file's upload progress
                fileToUpload.on('fileProgress', (file:any) => {
                    this.submitManager
                        .updateActiveUpload(file.trackingId, {
                            progress: Math.floor(file.progress() * 100),
                        })
                        .then(() => {
                            this._refreshActiveUploads(null, this.currentActiveSubmits);
                        });
                });

                fileToUpload.on('fileSuccess', (file:any, message:any) => {
                    // Capture the new assetContentId
                    this.submitMeta[file.trackingId].originalAssetContentId = message;

                    // Make a call to CWE via Node to begin ingest and delivery
                    let SpotDetails = this.submitMeta[file.trackingId];

                    this.LiteDeliveryResourceFactory.submitForDelivery({}, SpotDetails, () => {
                        this.NotificationService.showNotificationToast('Delivery Submitted.');
                    }, (err:any) => {
                        this.NotificationService.showNotificationToast('Delivery Failed to Submit');
                        console.log(err);
                    });
                });

                // Begin the upload
                fileToUpload.upload();
                this.NotificationService.showNotificationToast('Spot Upload Started');

                // Capture and reuse the duration
                this.sessionData = this.loginService.getSessionData();
                this.sessionData.userPreferences['spot.duration.last'] = this.submission.duration;
                this.loginService.setSessionData(this.sessionData);

                this.$scope.liteSubmissionForm.$setPristine();
                // Reset the form so the user can begin entering another spot
                this.reset(true);
                // Refresh the active upload/submit area to include the new data
                this.refreshSubmits();
            },
            function rejection() {}
        );
    }

    reset(postSubmit:Boolean) {
        if (!postSubmit) {
            this.submission = {};
        } else {
            var temps = {
                client: this.submission.client,
                agency: this.submission.agency,
                brand: this.submission.brand,
                duration: this.submission.duration,
                destination: this.submission.destination,
                additionalRecipients: this.submission.additionalRecipients,
            };
            this.submission = {
                client: temps.client,
                agency: temps.agency,
                brand: temps.brand,
                duration: temps.duration,
                destination: temps.destination,
                additionalRecipients: temps.additionalRecipients,
                description: '',
            };
        }
        this._reload();
        this.$scope.liteSubmissionForm.$setUntouched();
    }

    findClients(searchTerm:String) {
        let deferred = this.$q.defer();

        this.AdvertiserResourceFactory.getAll({ name: searchTerm }).subscribe(
            (advertisers:any) => {
                deferred.resolve(advertisers);
            },
            (err:any) => {
                deferred.resolve([]);
                console.debug(err);
            }
        );

        return deferred.promise;
    }

    // When converting the submit page to a TypeScript Angular Component, this can be simplified probably
    findAgencies(searchTerm:String) {
        let deferred = this.$q.defer();

        this.AgencyResourceFactory.getAll({ name: searchTerm, activeOnly: true }).subscribe(
            (agencies:any) => {
                deferred.resolve(agencies.rows);
            },
            (err:any) => {
                deferred.resolve([]);
                console.debug(err);
            }
        );

        return deferred.promise;
    }

    findBrands(searchTerm:String) {
        let deferred = this.$q.defer();

        this.BrandsResourceFactory.getAll({ name: searchTerm }).subscribe(
            (brands:any) => {
                var filteredBrands = this.filterDuplicates(brands);
                deferred.resolve(filteredBrands);
            },
            (err:any) => {
                deferred.resolve([]);
                console.debug(err);
            }
        );

        return deferred.promise;
    }

    filterDuplicates(data:any) {
        if (angular.isArray(data)) {
            let result = [];
            let key:any = {};
            for (let i = 0; i < data.length; i++) {
                let val = data[i];
                if (angular.isUndefined(key[val.name])) {
                    key[val.name] = val;
                    result.push(val);
                }
            }
            if (result.length > 0) {
                return result;
            }
        }
        return data;
    }

    liteDialog() {
        this.$mdDialog
            .show({
                controller: 'LiteDialogController',
                controllerAs: 'vm',
                template: require('./liteDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            })
            .then(() => {
                this.$state.go('liteSubmit', { newAccount: false });
            });
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _refreshActiveUploads(event:any, submits:any) {
        this.submitManager.getActiveUploads().then((activeUploads:any) => {
            // Update the active submits tracker
            this.activeSubmits = submits.map(function (cur:any) {
                return cur.id;
            });

            this.submitGridOptions.totalItems = submits.length + activeUploads.length;

            // Build status bar objects, so they aren't dynamically generated in the template, which creates an infinite digest cycle.
            let totalList = submits.concat(activeUploads);
            for (let i = 0; i < totalList.length; i++) {
                totalList[i].statusBar = this.retrieveStatus(totalList[i]);
            }

            this.submitGridOptions.data = totalList;

            // Stop spinning the refresh icon
            this.refreshingSubmits = false;
        });
    }

    _reload() {
        this.$timeout(() => {
            if (this.$stateParams.newAccount) {
                this.liteDialog();
            }
            this.brandData = this.loginService.brandInfo();
            this.submission.description = '';
            this.submission.withoutBaton = false;
            this.submission.duration = this.submission.duration
                ? this.submission.duration
                : this.sessionData.userPreferences['spot.duration.last'];
            this.submission.companyName = this.sessionData.accountName;
            this.submission.liteType =
            this.brandData && this.brandData.liteBranding && this.brandData.liteBranding.liteType
                    ? this.brandData.liteBranding.liteType
                    : this.sessionData.userType
                    ? this.sessionData.userType
                    : 'SPOTLIGHT';
            // If the isci required flag is set, use it. Otherwise, default to require isci.
            this.requireIsci =
                this.brandData &&
                this.brandData.liteBranding &&
                    (this.brandData.liteBranding.isciRequired === true ||
                        this.brandData.liteBranding.isciRequired === false)
                        ? this.brandData.liteBranding.isciRequired
                        : false;
            // Default isci limit to length of 100, since that's how big the DB field is
            this.maxIsciLength =
                this.brandData &&
                this.brandData.liteBranding &&
                this.brandData.liteBranding.isciLimit !== undefined
                        ? this.brandData.liteBranding.isciLimit
                        : 100;
            let searchDest =
                this.brandData &&
                this.brandData.destinationType &&
                this.brandData.destinationType.valueProperty
                    ? this.brandData.destinationType.valueProperty
                    : 'SPOTLIGHT';

            this.DestinationResourceFactory.getAll(
                { destinationType: 'eq:' + searchDest, active: true },
                (destinations:any) => {
                    this.destinations = destinations.data.rows;
                }
            );

            this.destinationType = this.sessionData.destinationType;
            if (this.submission.liteType === 'SPOTLIGHT') {
                this.GuideResourceFactory.get(
                    {
                        userType: this.submission.liteType,
                        docName: 'Comcast Spotlight Operations Centers and Their Markets',
                    },
                    (guide:any) => {
                        this.guide =
                            guide.data[0].apiUrl +
                            guide.data[0].filepath +
                            guide.data[0].filename;
                    }
                );
            }

            this.submitGridOptions.data = [];
            this.refreshSubmits();
        });
    }
}
