/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.submit').controller('LiteDialogController', ['$state', '$mdDialog', 'AuthenticationResourceFactory', 'EndPointService', 'GuideResourceFactory', 'loginService', '$window', function ($state, $mdDialog, AuthenticationResourceFactory, EndPointService, GuideResourceFactory, loginService, $window) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.guideId = null;
    /* BINDABLE : METHODS*/

    vm.download = download;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function download() {
      // Make request to get __sessionKey
      AuthenticationResourceFactory.getSessionKey({}, function (sessionKey) {
        // Append __sessionKey to request to Node (instead of RE)
        $window.open(EndPointService.guideDownloadEndPoint.replace(':id', 'AdDelivery Lite File Format Specification Sheet') + '?id=' + vm.guideId + '&sessionKey=' + sessionKey.data, '_blank'); // NODE: Take __sessionKey and pipe request to RE
        // NODE: Open request directly into response to stream data back to browser
      }, function (err) {// Unable to retrieve the session key, maybe the user is logged out.
      });
      $mdDialog.hide();
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      GuideResourceFactory.getOne({
        userType: loginService.getSessionData().userType,
        docName: 'AdDelivery Lite File Format Specification Sheet'
      }, function (guide) {
        vm.guideId = guide.data[0].id;
      });
    }
  }]);
})();